import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  ClickAwayListener,
  Dialog,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
/*import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';*/
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { PhoneInput, defaultCountries, parseCountry, CountryData } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { PhoneNumberUtil } from 'google-libphonenumber';
import classnames from 'classnames';

import { ConfirmResultEnum } from 'context';
import { CalendarIcon, ChevronRightIcon, ClockIcon, CrossIcon } from 'assets';
import { FormInput, Spinner } from 'components';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { ApiUser } from 'types';
import { CreateCalendarInviteType, IApiRequests, useApiRequests, useCreateCalendarInvite } from 'hooks';

import styles from './InviteClientPopup.module.scss';

type InviteType = 'sms' | 'email' | 'calendar';
type CalendarType = 'google' | 'outlook' | 'iCal';

type InviteClientFormType = {
  invitationMessage: string;
  email: string;
};

export type InviteClientPopupProps = {
  open: boolean;
  onClose(): void;
  onConfirm(confirmResult: ConfirmResultEnum): void;
};

const schema = Yup.object().shape({
  invitationMessage: Yup.string().max(500, 'Max length is 500 character').required().label('Invitation Message'),
  email: Yup.string().email().required().label('Email'),
});

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export function InviteClientPopup({ open, onClose, onConfirm }: InviteClientPopupProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { createGoogleEvent, createOutlookEvent, createICalEvent }: CreateCalendarInviteType =
    useCreateCalendarInvite();
  const { inviteToCallBySms, inviteToCallByEmail }: IApiRequests = useApiRequests();
  const user: ApiUser | null = useAppSelector(selectUser);

  const [loading, setLoading] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [selectedInviteType, setSelectedInviteType] = useState<InviteType>('calendar');
  const [calendarType, setCalendarType] = useState<CalendarType>('google');
  const [dateSelectorIsOpen, setDateSelectorIsOpen] = useState<boolean>(false);
  const [dateSelected, setDateSelected] = useState<Moment>(moment());
  const [timeFromSelected, setTimeFromSelected] = useState<Moment>(
    moment([moment().year(), moment().month(), moment().date(), 10, 0]),
  );
  const [timeToSelected, setTimeToSelected] = useState<Moment>(
    moment([moment().year(), moment().month(), moment().date(), 11, 0]),
  );
  const [phone, setPhone] = useState<string>('');
  const [isPhoneInputTouched, setIsPhoneInputTouched] = useState<boolean>(false);
  const phoneIsValid: boolean = isPhoneValid(phone);

  const formatedSelectedDate: string = useMemo(() => {
    return dateSelected.format('MM/DD/YYYY');
  }, [dateSelected]);

  const selectTimeOptions: { [key: string]: Moment } = useMemo(() => {
    const options: { [key: string]: Moment } = {};
    const tempTime: Moment = moment([dateSelected.year(), dateSelected.month(), dateSelected.date()]);

    while (tempTime.date() <= dateSelected.date()) {
      options[tempTime.format('hh:mm A')] = tempTime.clone();
      tempTime.add(15, 'm');
    }

    return options;
  }, [dateSelected]);

  const availableTimeFromSelectOptions: { [key: string]: string } = useMemo(() => {
    const options: { [key: string]: string } = {};
    const currentTime: Moment = moment();

    Object.keys(selectTimeOptions).forEach((key: string) => {
      const tempTime: Moment = selectTimeOptions[key];

      if (currentTime.isBefore(tempTime)) {
        options[key] = key;
      }
    });

    return options;
  }, [selectTimeOptions, dateSelected]);

  const fromDate: Moment = useMemo(() => {
    return moment([
      dateSelected.year(),
      dateSelected.month(),
      dateSelected.date(),
      timeFromSelected.hour(),
      timeFromSelected.minute(),
    ]);
  }, [dateSelected, timeFromSelected]);

  const availableTimeToSelectOptions: { [key: string]: string } = useMemo(() => {
    const options: { [key: string]: string } = {};

    let tempTime: Moment = moment();

    Object.keys(selectTimeOptions).forEach((key: string) => {
      tempTime = selectTimeOptions[key];

      if (fromDate.isBefore(tempTime)) {
        options[key] = key;
      }
    });

    return options;
  }, [selectTimeOptions, fromDate]);

  const toDate: Moment = useMemo(() => {
    return moment([
      dateSelected.year(),
      dateSelected.month(),
      dateSelected.date(),
      timeToSelected.hour(),
      timeToSelected.minute(),
    ]);
  }, [dateSelected, timeToSelected]);

  const userFullName: string = useMemo(() => {
    let fullName = user!.clinicUser.preferedDisplayName;
    if (!fullName) {
      fullName = [user!.title, user!.firstName, user!.lastName].filter((text: string | undefined) => !!text).join(' ');

      if (user?.clinicUser.suffix) {
        fullName += `, ${user?.clinicUser.suffix}`;
      }
    }

    return fullName;
  }, [user]);

  const formDefaultValues = useMemo(
    () => ({
      invitationMessage: `Hello, ${userFullName} has invited you to join an online session. Click the link below to check-in.\n\n${
        window.location
      }${user!.clinicUser.roomName}`,
    }),
    [userFullName],
  );

  const countriesForPhoneInput: CountryData[] = useMemo(() => {
    return defaultCountries.filter((country) => {
      const { iso2 } = parseCountry(country);
      return ['us'].includes(iso2);
    });
  }, []);

  const form = useForm<InviteClientFormType>({
    resolver: yupResolver(schema),
    defaultValues: formDefaultValues,
    mode: 'all',
  });

  const buttonText: string = useMemo(() => {
    if (selectedInviteType === 'calendar') {
      return 'Launch calendar';
    }

    return 'Send invitation';
  }, [selectedInviteType]);

  const lounchCalendar = useCallback((): void => {
    if (!isFormValid) {
      return;
    }

    const formData: InviteClientFormType = form.getValues();

    switch (calendarType) {
      case 'google':
        createGoogleEvent(
          fromDate,
          toDate,
          `Online session with ${userFullName}`,
          formData.invitationMessage,
          `${window.location}${user!.clinicUser.roomName}`,
        );
        break;
      case 'outlook':
        createOutlookEvent(
          fromDate,
          toDate,
          `Online session with ${userFullName}`,
          formData.invitationMessage,
          `${window.location}${user!.clinicUser.roomName}`,
        );
        break;
      case 'iCal':
        createICalEvent(
          fromDate,
          toDate,
          `Online session with ${userFullName}`,
          formData.invitationMessage,
          `${window.location}${user!.clinicUser.roomName}`,
        );
        break;
    }
  }, [
    calendarType,
    fromDate,
    toDate,
    userFullName,
    isFormValid,
    user,
    createGoogleEvent,
    createOutlookEvent,
    createICalEvent,
  ]);

  const inviteBySms = useCallback((): void => {
    if (!isFormValid) {
      return;
    }

    setLoading(true);

    inviteToCallBySms({ phoneNumber: phone })
      .then(() => {
        onConfirm(ConfirmResultEnum.Confirm);
      })
      .catch((error: Error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isFormValid, phone, inviteToCallBySms]);

  const inviteByEmail = useCallback((): void => {
    if (!isFormValid) {
      return;
    }

    setLoading(true);

    const formData: InviteClientFormType = form.getValues();

    inviteToCallByEmail({ email: formData.email })
      .then(() => {
        onConfirm(ConfirmResultEnum.Confirm);
      })
      .catch((error: Error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isFormValid, inviteToCallByEmail]);

  useEffect(() => {
    const currentTime: Moment = moment();
    if (fromDate.isBefore(currentTime)) {
      const minutesToAdd: number = currentTime.minute() % 15;
      setTimeFromSelected(currentTime.add(15 - minutesToAdd, 'minute'));
    }
  }, [fromDate]);

  useEffect(() => {
    if (toDate.isSameOrBefore(fromDate)) {
      setTimeToSelected(fromDate.clone().add(15, 'minute'));
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (selectedInviteType === 'calendar') {
      setIsFormValid(true);
    } else if (selectedInviteType === 'sms') {
      setIsFormValid(phoneIsValid);
    } else {
      setIsFormValid(form.formState.isValid);
    }
  }, [selectedInviteType, phoneIsValid, form.formState.isValid]);

  const handleSelectChange = (event: SelectChangeEvent<InviteType>): void => {
    setSelectedInviteType(event.target.value as InviteType);
  };

  const handleOpenDateSelector = (): void => {
    setDateSelectorIsOpen(true);
  };

  const handleDateChange = (value: Moment | null): void => {
    setDateSelectorIsOpen(false);
    setDateSelected(value!);
  };

  const handleCloseDateSelector = (): void => {
    setDateSelectorIsOpen(false);
  };

  const handleTimeFromChange = (event: SelectChangeEvent<string>): void => {
    setTimeFromSelected(selectTimeOptions[event.target.value]);
  };

  const handleTimeToChange = (event: SelectChangeEvent<string>): void => {
    setTimeToSelected(selectTimeOptions[event.target.value]);
  };

  const handleChangeCalendarType = (event: ChangeEvent<HTMLInputElement>, value: string): void => {
    setCalendarType(value as CalendarType);
  };

  const handlePhoneCahnge = (phone: string): void => {
    setPhone(phone);
  };

  const handlePhoneInputBlur = (): void => {
    setIsPhoneInputTouched(true);
  };

  const handleSendInvite = useCallback((): void => {
    switch (selectedInviteType) {
      case 'calendar':
        lounchCalendar();
        break;
      case 'sms':
        inviteBySms();
        break;
      case 'email':
        inviteByEmail();
        break;
    }
  }, [selectedInviteType, lounchCalendar, inviteBySms, inviteByEmail]);

  return (
    <Dialog className={styles.dialog} classes={{ paper: styles.dialogPaper }} open={open} onClose={onClose}>
      {loading && <Spinner />}

      <div className={styles.header}>
        <div className={styles.leftHeaderSide}>
          <Typography variant='body4'>INVITE VIA</Typography>

          <Select
            fullWidth
            variant='outlined'
            className={styles.select}
            classes={{ icon: styles.selectIcon }}
            IconComponent={ChevronRightIcon}
            value={selectedInviteType}
            onChange={handleSelectChange}
          >
            <MenuItem value={'calendar'}>Calendar</MenuItem>
            <MenuItem value={'sms'}>SMS</MenuItem>
            <MenuItem value={'email'}>Email</MenuItem>
          </Select>
        </div>

        <CrossIcon className={styles.closeIcon} color='primary' onClick={onClose} />
      </div>

      {selectedInviteType === 'calendar' && (
        <>
          <div className={styles.dateSelectorRow}>
            <CalendarIcon fill='white' stroke='#81CFDD' />

            <div className={styles.openDateSelectorButton} onClick={handleOpenDateSelector}>
              {formatedSelectedDate}
            </div>

            {dateSelectorIsOpen && (
              <>
                <div className={styles.dateSelectorContainer}></div>
              </>
            )}
          </div>

          <div className={styles.timeSelectorRow}>
            <ClockIcon stroke='#81CFDD' fill='white' />

            <Select
              variant='outlined'
              className={styles.select}
              classes={{ icon: styles.selectIcon }}
              MenuProps={{ classes: { list: styles.timeSelectList } }}
              IconComponent={ChevronRightIcon}
              value={timeFromSelected.format('hh:mm A')}
              onChange={handleTimeFromChange}
              size='small'
            >
              {Object.keys(availableTimeFromSelectOptions).map((optionKey: string) => (
                <MenuItem value={optionKey} key={optionKey}>
                  {availableTimeFromSelectOptions[optionKey]}
                </MenuItem>
              ))}
            </Select>

            <Typography variant='body5'>to</Typography>

            <Select
              variant='outlined'
              className={styles.select}
              classes={{ icon: styles.selectIcon }}
              MenuProps={{ classes: { list: styles.timeSelectList } }}
              IconComponent={ChevronRightIcon}
              value={timeToSelected.format('hh:mm A')}
              onChange={handleTimeToChange}
            >
              {Object.keys(availableTimeToSelectOptions).map((optionKey: string) => (
                <MenuItem value={optionKey} key={optionKey}>
                  {availableTimeToSelectOptions[optionKey]}
                </MenuItem>
              ))}
            </Select>
          </div>
        </>
      )}

      {selectedInviteType === 'sms' && (
        <div className={styles.row}>
          <Typography variant='body2'>Client Phone Number</Typography>

          <PhoneInput
            countries={countriesForPhoneInput}
            defaultCountry='us'
            className={classnames({
              [styles.invalidPhone]: !phoneIsValid && isPhoneInputTouched,
            })}
            inputClassName={classnames(styles.phoneInput, {
              [styles.invalidPhoneInput]: !phoneIsValid && isPhoneInputTouched,
            })}
            placeholder='Enter phone number'
            value={phone}
            onBlur={handlePhoneInputBlur}
            onChange={handlePhoneCahnge}
          />

          {!phoneIsValid && isPhoneInputTouched && <span className={styles.errorMessage}>Invalid Phone Number</span>}
        </div>
      )}

      {selectedInviteType === 'email' && (
        <div className={styles.row}>
          <Typography variant='body2'>Client Email</Typography>

          <FormInput
            name='email'
            control={form.control}
            errorHelperClassName={styles.errorHelper}
            placeholder='Enter email address'
          />
        </div>
      )}

      <div className={styles.row}>
        <Typography variant='body2'>Invitation Message</Typography>

        <FormInput
          name='invitationMessage'
          disabled={true}
          control={form.control}
          multiline
          maxRows={4}
          className={styles.textareas}
          errorHelperClassName={styles.errorHelper}
        />
      </div>

      {selectedInviteType === 'calendar' && (
        <RadioGroup
          name='selectCalendarType'
          value={calendarType}
          className={styles.radioGroup}
          onChange={handleChangeCalendarType}
        >
          <FormControlLabel value={'google'} control={<Radio className={styles.check} />} label='Google Calendar' />
          <FormControlLabel value={'outlook'} control={<Radio className={styles.check} />} label='Outlook 365' />
          <FormControlLabel value={'iCal'} control={<Radio className={styles.check} />} label='Default Calendar' />
        </RadioGroup>
      )}

      <Button variant='contained' fullWidth onClick={handleSendInvite} disabled={!isFormValid}>
        {buttonText}
      </Button>
    </Dialog>
  );
}
